<template>
	<div class="container d-flex flex-column justify-content-center">

		<div class=" mt-2">
			<div class="card-body text-center h-100-vh d-flex align-items-center flex-column justify-content-center ">
					<!-- <img class="img-contain-150" src="@/assets/images/logo-ecart.png" alt=""> -->
				<p class="f-40"><span class="text-success">404</span> </p>
				<p class="f-w-600" v-html="$t('messages.404Message')"></p>
				<hr>
				<!-- <router-link class="btn btn-link btn-lg" :to="{ path: user.id ? '/dashboard' : '/' }" v-text="$t('messages.backToHome')">
				</router-link> -->
			</div>
		</div>

	</div>
</template>
<script>
	import {
		mapState,
	} from "vuex";

	export default {
		computed: {
			...mapState({
				user: state => state.session.user,
			}),
		},
	}
</script>

<style lang="scss">

</style>